import { Component, DestroyRef, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor } from '@angular/common';
import { Subject } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ListFilter } from '@parashift/shared/models';
import { FilterConfig } from '@parashift/shared/models';
import { ColumnsService } from 'shared/components/columns/columns.service';
import { FilterService, SessionService } from '@parashift/shared/services';
import { FilterLoaderComponent } from './filter-loader/filter-loader.component';

@Component({
  selector: 'filter-column',
  templateUrl: './filter-column.component.html',
  standalone: true,
  imports: [FaIconComponent, NgFor, FilterLoaderComponent]
})
export class FilterColumnComponent implements OnInit {

  @Input() filterConfig: FilterConfig;
  @Input() listFilter: ListFilter;

  $filterParams = new Subject<ListFilter>();
  groupBy$: Subject<string> = new Subject();

  constructor(
    private sessionService: SessionService,
    private filterService: FilterService,
    private columnsService: ColumnsService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.initFilterBypassListener();
    this.initFilterGroupByListener();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFilterChanged(listFilter) {
    this.$filterParams.next(this.listFilter);
  }

  setPageValue(event: Partial<PageChangedEvent>) {
    if (this.listFilter.page.number !== event.page) {
      const storeFilterParams = {
        allowMultipleInstances: false,
        identifier: this.filterConfig.meta.identifier,
        filterType: 'page',
        field: 'number',
        value: event.page
      };

      this.filterService.listPageChanged();
      this.filterService.storeFilter(storeFilterParams);
      this.listFilter.page.number = event.page;
      this.onFilterChanged(this.listFilter);
    }
  }

  setPageSize(pageSize: number) {
    if (this.sessionService.global_page_size !== pageSize) {
      this.sessionService.global_page_size = pageSize;
      this.listFilter.page = { number: 1, size: pageSize };
      const storeFilterParams = {
        allowMultipleInstances: false,
        identifier: this.filterConfig.meta.identifier,
        filterType: 'page',
        field: 'number',
        value: 1
      };

      this.filterService.storeFilter(storeFilterParams);
      this.onFilterChanged(this.listFilter);
    }
  }

  initFilterBypassListener() {
    this.filterService.byPassFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(listFilter => {
      this.listFilter = listFilter as ListFilter;
      this.onFilterChanged(listFilter);
    });
  }

  closeFilterColumn() {
    this.columnsService.toggleLeftColumn(false, 'filter');
  }

  private initFilterGroupByListener() {
    this.filterService.groupBy$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(field => {
      this.groupBy$.next(field as string);
    });
  }
}
