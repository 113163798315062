import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, AsyncPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts, NgxBootstrapMultiselectModule } from '@parashift/ngx-bootstrap-multiselect';
import { FilterComponent } from 'shared/components/filter-column/filters/filter.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { SessionService } from '@parashift/shared/services';
import { Logger } from '@parashift/shared/services';
import { Option } from 'shared/components/filter-column/filters/selectbox/selectbox.component';
import { mapOptionsToMultiSelectboxOptions } from '@parashift/shared/utils';

@Component({
  selector: 'filter-multi-selectbox[filter=multi-selectbox]',
  templateUrl: './multi-selectbox.component.html',
  styleUrls: ['./multi-selectbox.component.scss'],
  standalone: true,
  imports: [NgIf, NgxBootstrapMultiselectModule, ReactiveFormsModule, FormsModule, FaIconComponent, AsyncPipe]
})
export class MultiSelectboxComponent extends FilterComponent {
  options$: Observable<IMultiSelectOption[]>;
  allowMultipleInstances = true;
  isListReloading = false;
  showListReload = false;

  settings: IMultiSelectSettings = {
    enableSearch: false,
    buttonClasses: 'form-control form-control-sm',
    dynamicTitleMaxItems: 5,
  };

  texts: IMultiSelectTexts = {};

  get selectedOptions() { return this._selectedOptions; }
  set selectedOptions(options) {
    const newValue = options.join(',');
    this._selectedOptions = options;
    this.setFieldVariants(this.config.field, newValue);
    this.setListFilter();
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: newValue });
  }
  private _selectedOptions: string[] = []; // array of options' ids

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
    ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter(): void {
    this.initListReload();
    this.initTexts();

    if (this.restoredFilter.value && Array.isArray(this.restoredFilter.value) && this.restoredFilter.value[0]) {
      this.selectedOptions = this.restoredFilter.value[0].split(',').filter(x => x !== '');
    }
    const { options } = this.config;
    const options$ = isObservable(options) ? options as Observable<Option[]> : of(options);
    this.options$ = options$.pipe(
      map(opts => mapOptionsToMultiSelectboxOptions(opts, x => x)),
      takeUntilDestroyed(this.destroyRef)
    );
    this.settings.enableSearch = this.config.enableSearch || false;
  }

  initListReload() {
    if (this.config.reloadOptions && typeof this.config.reloadOptions.reload === 'function') {
      this.showListReload = true;
    }
  }

  initTexts() {
    this.texts = {
      defaultTitle: $localize `:@@shared.filter_column.multiselectbox.default_title:select`,
      checked: $localize `:@@shared.filter_column.multiselectbox.checked:checked`,
      checkedPlural: $localize `:@@shared.filter_column.multiselectbox.checked:checked`
    };
  }

  setFilter(filter: Filter): void {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.selectedOptions = filter.value.split(',').filter(x => x !== '');
      this.setFieldVariants(this.config.field, filter.value[0]);
      this.setListFilter();
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field && filter?.value && filter.value[0]) {
      this._selectedOptions = filter.value[0].split(',').filter(x => x !== '');
      this.setFieldVariants(this.config.field, filter.value[0], false);
    }
  }

  setListFilter() {
    this.changeFilter(this.listFilter);
  }

  setFieldVariants(field, value, store = true) {
    value = value || '';
    const variant = '' + value.split('_');
    let result = [];

    const filter = this.listFilter.filter || {};
    delete filter[field];

    switch (variant[0]) {
      case 'blank': {
        result = [field + '_' + 'blank', variant[1]];
        break;
      }
      default: {
        result = [field, value];
        delete this.listFilter.filter[field + '_blank'];
      }
    }

    filter[result[0]] = result[1] ? [result[1]] : [];
    store && this.storeFilter(field, result[1] ? [value] : []);
    this.listFilter.filter = filter;
  }

  reloadList() {
    this.isListReloading = true;
    this.config.reloadOptions.reload().subscribe(
      () => this.isListReloading = false,
      () => this.isListReloading = false
    );
  }

  resetFilter(): void {
    const value = this.config.defaultValue || '';
    this.selectedOptions = [];
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value });
  }
}
