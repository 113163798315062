<div class="card filter-column scroll-container" style="overflow-y: auto; max-height: calc(100vh - 123px); box-shadow: none; border: thin solid #c4c4c4">
  <div class="card-header">
    <div class="row">
      <div class="col-auto filter-title" i18n="@@common.filters">Filters</div>
      <div class="col text-end"><fa-icon [icon]="['fas', 'times-circle']" (click)="closeFilterColumn()" class="pointer"></fa-icon></div>
    </div>
  </div>
  <div class="card-body">
    <filter-loader *ngFor="let filter of filterConfig?.filters"
                   [type]="filter.type"
                   [config]="filter.config"
                   [listFilter]="listFilter"
                   [identifier]="filterConfig.meta?.identifier"
                   (onFilterChange)="onFilterChanged($event)">
    </filter-loader>
  </div>
</div>
