import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-sorting[filter=sorting]',
  templateUrl: './sorting.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class SortingComponent extends FilterComponent {
  sortingField: UntypedFormControl;
  sortingDirection: UntypedFormControl;
  options: { label: string; value: string; }[];
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    const initialFilter = this.getInitalValues();

    this.sortingField = new UntypedFormControl(initialFilter.field);
    this.sortingDirection = new UntypedFormControl(initialFilter.direction);
    this.options = this.config.options;

    if (this.listFilter.sorting) {
      this.listFilter.sorting.direction = initialFilter.direction ? '-' : '';
      this.listFilter.sorting.field = initialFilter.field;
    } else {
      this.listFilter.sorting = {
        direction: initialFilter.direction ? '-' : '',
        field: initialFilter.field
      };
    }

    this.sortingField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((field: string) => {
      this.setListFilterForField(field);
      this.storeFilter('sort', this.listFilter.sorting.direction + this.listFilter.sorting.field);
      this.filterService.emitFilterChange({ type: this.type, field: 'field', value: field });
    });

    this.sortingDirection.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((direction: string) => {
      this.setListFilterForDirection(direction);
      this.storeFilter('sort', direction ? '-' + this.listFilter.sorting.field : '' + this.listFilter.sorting.field);
      this.filterService.emitFilterChange({ type: this.type, field: 'direction', value: direction });
    });
  }

  getInitalValues() {
    const restoredValue = this.restoredFilter.value;
    let direction: string | boolean;
    let field = '';

    if (restoredValue) {
      direction = restoredValue.substr(0, 1);
      field = restoredValue;

      if (direction === '-') {
        field = restoredValue.substr(1);
        direction = true;
      } else {
        direction = false;
      }
    } else {
      field = this.config.defaultValues.field;
      direction = this.config.defaultValues.desc;
      this.storeFilter('sort', (direction ? '-' : '') + field);
    }

    return { direction, field };
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === 'field') {
      this.sortingField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter('sort', this.listFilter.sorting.direction + filter.value);
      this.setListFilterForField(filter.value);
    } else if (filter.type === this.type && filter.field === 'direction') {
      this.sortingDirection.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter('sort', filter.value + this.listFilter.sorting.field);
      this.setListFilterForDirection(filter.value);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.value) {
      const values = this.getUserFilterValues(filter.value);
      this.sortingField.setValue(values.field, { emitEvent: false, emitModelToViewChange: true });
      this.sortingDirection.setValue(values.direction, { emitEvent: false, emitModelToViewChange: true });
      if (this.listFilter.sorting) {
        this.listFilter.sorting.direction = values.direction ? '-' : '';
        this.listFilter.sorting.field = values.field;
      } else {
        this.listFilter.sorting = {
          direction: values.direction ? '-' : '',
          field: values.field
        };
      }
      this.storeFilter('sort', this.listFilter.sorting.direction + this.listFilter.sorting.field);
    }
  }

  getUserFilterValues(value: string) {
    let direction: string | boolean;
    let field = '';

    if (value) {
      direction = value.substring(0, 1);
      field = value;

      if (direction === '-') {
        field = value.substring(1);
        direction = true;
      } else {
        direction = false;
      }
    } else {
      field = this.config.defaultValues.field;
      direction = this.config.defaultValues.desc;
    }

    return { direction, field };
  }


  setListFilterForField(value) {
    if (this.listFilter.sorting) {
      this.listFilter.sorting.field = value;
    } else {
      this.listFilter.sorting = {
        field: value
      };
    }

    this.changeFilter(this.listFilter);
  }

  setListFilterForDirection(value) {
    if (this.listFilter.sorting) {
      this.listFilter.sorting.direction = value ? '-' : '';
    } else {
      this.listFilter.sorting = {
        direction: value ? '-' : ''
      };
    }

    this.changeFilter(this.listFilter);
  }

  resetFilter() {
    const field = this.config.defaultValues.field || 'id';
    const direction = this.config.defaultValues.desc || true;

    this.setListFilterForField(field);
    this.storeFilter('sort', (direction ? '-' : '') + field);
    this.filterService.emitFilterChange({ type: this.type, field: 'field', value: field });

    this.setListFilterForDirection(direction);
    this.storeFilter('sort', direction ? '-' + field : '' + field);
    this.filterService.emitFilterChange({ type: this.type, field: 'direction', value: direction });
  }
}
