import { Injectable } from '@angular/core';
import { CheckboxComponent } from './filters/checkbox/checkbox.component';
import { DateRangeComponent } from './filters/date-range/date-range.component';
import { DocumentTypeComponent } from './filters/document-type/document-type.component';
import { GlobalRolesComponent } from './filters/global-roles/global-roles.component';
import { GroupByComponent } from './filters/group-by/group-by.component';
import { HeadlineComponent } from './filters/headline/headline.component';
import { IdSearchComponent } from './filters/id-search/id-search.component';
import { MultiSelectboxComponent } from './filters/multi-selectbox/multi-selectbox.component';
import { MultiselectTypeaheadComponent } from './filters/multiselect-typeahead/multiselect-typeahead.component';
import { NumberRangeComponent } from 'shared/components/filter-column/filters/number-range/number-range.component';
import { ResetComponent } from './filters/reset/reset.component';
import { SearchComponent } from './filters/search/search.component';
import { SelectboxComponent } from './filters/selectbox/selectbox.component';
import { SortingComponent } from './filters/sorting/sorting.component';
import { TenantUsersComponent } from './filters/tenant-users/tenant-users.component';
import { TagsAllComponent } from './filters/tags-all/tags-all.component';
import { TagsAnyComponent } from './filters/tags-any/tags-any.component';

@Injectable()
export class FilterMappings {
  checkbox = CheckboxComponent;
  'date-range' = DateRangeComponent;
  'document-type' = DocumentTypeComponent;
  'global-roles' = GlobalRolesComponent;
  'group-by' = GroupByComponent;
  headline = HeadlineComponent;
  'id-search' = IdSearchComponent;
  'multiselectbox' = MultiSelectboxComponent;
  'multiselect-typeahead' = MultiselectTypeaheadComponent;
  'number-range' = NumberRangeComponent;
  reset = ResetComponent;
  search = SearchComponent;
  selectbox = SelectboxComponent;
  sorting = SortingComponent;
  'tags-all' = TagsAllComponent;
  'tags-any' = TagsAnyComponent;
  'tenant-users' = TenantUsersComponent;
}
