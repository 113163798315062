import { EventEmitter, Input, OnInit, Output, Directive, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HideConfig, ListFilter } from '@parashift/shared/models';
import { FilterService, Filter, Logger, RestoreFilterParams, SessionService, StoreFilterParams } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FilterComponent implements OnInit {
  @Input() config: any;
  @Input() listFilter: ListFilter;
  @Input() identifier: string;
  @Input() type: string;
  @Output() onFilterChange: EventEmitter<string>;

  protected restoredFilter: any = { field: '', value: '' };

  abstract allowMultipleInstances: boolean;

  storeFilterParams: StoreFilterParams;
  restoreFilterParams: RestoreFilterParams;
  hide: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    destroyRef.onDestroy(() => {
      if (this.config.hide && this.config.hide instanceof BehaviorSubject) {
        this.config.hide.complete();
      }
    });
  }

  abstract initFilter(): void;
  abstract setFilter(filter: Filter): void;
  abstract resetFilter(forceResetAfterHiding?: boolean): void;
  abstract setUserFilterFromTab(filter: Filter): void;

  ngOnInit() {
    this.setParams();
    this.restoreFilter();
    this.initChangeListener();
    this.initResetFilterListener();
    this.initHideListener();
    this.initFilter();
    this.initFilterTabListener();
  }

  initHideListener() {
    if (this.config.hide && this.config.hide instanceof BehaviorSubject) {
      this.config.hide.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hide: HideConfig) => {
        if (Object.prototype.hasOwnProperty.call(hide, this.config.field)) {
          this.hide = hide[this.config.field];
          if (this.hide) {
            this.resetFilter(true);
          }
        }
      });
    }
  }

  changeFilter(filter) {
    this.onFilterChange.emit(filter);
  }

  setParams() {
    this.storeFilterParams = {
      allowMultipleInstances: this.allowMultipleInstances,
      identifier: this.identifier,
      filterType: this.type,
      field: '',
      value: undefined
    };

    this.restoreFilterParams = {
      allowMultipleInstances: this.allowMultipleInstances,
      identifier: this.identifier,
      filterType: this.type,
      field: this.config.field || this.config.filter || undefined
    };
  }

  storeFilter(field: string, value?: any) {
    this.storeFilterParams.field = field;
    this.storeFilterParams.value = value;
    this.filterService.storeFilter(this.storeFilterParams);
  }

  restoreFilter() {
    this.restoredFilter = this.filterService.restoreFilter(this.restoreFilterParams);
  }

  initResetFilterListener() {
    this.filterService.resetFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.resetFilter();
    });
  }

  initChangeListener() {
    this.filterService.setFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filter => {
      this.setFilter(filter as Filter);
    });
  }

  initFilterTabListener() {
    this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const filter = this.filterTabsService.getUserDefinedFilter(this.type, this.config.field);
      this.setUserFilterFromTab(filter);
    });
  }
}
