<div class="form-group" *ngIf="!hide">
  <label class="form-control-label">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.missing_label">Missing label!</ng-template>
  </label>
  <ngx-bootstrap-multiselect [options]="options$ | async" [(ngModel)]="selectedOptions" [settings]="settings"></ngx-bootstrap-multiselect>
  <small *ngIf="showListReload" class="form-text text-muted ng-star-inserted">
    <a (click)="reloadList()" class="pointer plain me-1" i18n="@@shared.filter_column.reload_list">Reload list</a>
    <fa-icon [icon]="['fas', 'sync-alt']"
             [spin]="isListReloading">
    </fa-icon>
  </small>
</div>
