import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor, NgIf, SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Observable, Observer, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { FilterComponent } from '../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { Filter, FilterService, Logger, SessionService, TagsRegister } from '@parashift/shared/services';
import { HighlightMatchPipe, MarkAnyAndNoneOptionsPipe } from '@parashift/shared/pipes';
import { FilterAutofocusDirective } from '../../directives/filter-autofocus.directive';

export interface TagSourceItem {
  key: string;
  value: string;
}

@Component({
  selector: 'filter-tag-all[filter=tags-all]',
  templateUrl: './tags-all.component.html',
  standalone: true,
  imports: [
    FaIconComponent,
    FilterAutofocusDirective,
    HighlightMatchPipe,
    MarkAnyAndNoneOptionsPipe,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    SlicePipe,
    TooltipModule,
    TypeaheadModule,
  ]
})
export class TagsAllComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = false;
  dataSource: Observable<TagSourceItem[]>;
  source: TagSourceItem[];
  items = [];
  excludeKeys = [];
  isListReloading = false;

  private field = 'tags_contain';
  private fieldVariant = 'tags_empty';

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected tagsRegister: TagsRegister,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.initSource();
    this.initTypeahead();
    this.formField = new UntypedFormControl();
    this.initValue();
    this.initExcludedKeys();
    this.initResetForeignFilterListener();
  }

  search(term: any): Observable<TagSourceItem[]> {
    if (!term) {
      return of(this.removeSelectedItems(this.source));
    }

    const query = new RegExp(term, 'ig');
    const result = this.source.filter((item: any) => query.test(item.value));
    return of(this.removeSelectedItems(result));
  }

  onSelect(event: TypeaheadMatch) {
    if (event.item.key === 'true') {
      // Reset tags-any filter in case it has set tags_empty filter as well
      this.listFilter.filter.tags_empty?.[0] === 'false' && this.filterService.resetForeignFilter({ type: 'tags-any', value: 'false' });
      this.items = [event.item];
      this.excludeKeys = this.tagsRegister.getFilterItems();
    } else {
      this.items.push(event.item);
      this.excludeKeys.push(event.item.key);
      this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    }
    this.setFieldVariants();
    this.setListFilter();
    this.filterService.emitFilterChange({ type: this.type, value: this.items });
    this.items.length === 0 && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: this.items });
  }

  unlinkItem(index: number) {
    this.items.splice(index, 1);
    this.excludeKeys.splice(index, 1);
    this.setFieldVariants();
    this.setListFilter();
    this.filterService.emitFilterChange({ type: this.type, value: this.items });
    this.items.length === 0 && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: this.items });
  }

  onFocus() {
    if (this.items[0]?.key === 'true') {
      this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
      this.unlinkItem(0);
    }
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type) {
      if (!filter.value) {
        this.items = [];
        this.excludeKeys = [];
      }
      this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
      this.setFieldVariants();
      this.setListFilter();
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type) {
      if (!filter.value) {
        this.items = [];
        this.excludeKeys = [];
      } else {
        this.items = Array.isArray(filter.value) ? filter.value : [];
        this.excludeKeys = [];
        this.items.forEach(item => this.excludeKeys.push(item.key));
        if (this.items[0]?.key === 'true') {
          this.formField.setValue(this.items[0].value, { emitEvent: false, emitModelToViewChange: true });
        }
      }
      this.setFieldVariants(false);
    }
  }

  setListFilter() {
    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.items = [];
    this.excludeKeys = [];
    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.setFieldVariants();
    this.setListFilter();
    this.filterService.emitFilterChange({ type: this.type, value: '' });
  }

  reloadList() {
    this.isListReloading = true;
    this.tagsRegister.reload()
    .pipe(finalize(() => {
      this.initSource();
      this.initExcludedKeys();
    }))
    .subscribe({
      next: () => this.isListReloading = false,
      error: () => this.isListReloading = false,
    });
  }

  private initTypeahead() {
    this.dataSource = new Observable((observer: Observer<TagSourceItem[]>) => {
      observer.next(this.formField.value);
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(term => this.search(term))
    );
  }

  private initSource() {
    this.source = this.tagsRegister.getFilterItems('all');
  }

  private initValue() {
    this.items = this.restoredFilter.value && Array.isArray(this.restoredFilter.value) ? this.restoredFilter.value : [];
    if (this.items[0]?.key === 'true') {
      this.formField.setValue(this.items[0].value, { emitEvent: false, emitModelToViewChange: false });
    }
  }

  private initExcludedKeys() {
    this.excludeKeys = [];
    this.items.forEach(item => this.excludeKeys.push(item.key));
  }

  private removeSelectedItems(source) {
    const result = [];

    if (this.items[0]?.key === 'true') {
      return result;
    }

    source.forEach((sourceItem) => {
      if (!this.items || !this.items.find(item => item.key === sourceItem.key)) {
        result.push(sourceItem);
      }
    });

    return result;
  }

  private initResetForeignFilterListener() {
    this.filterService.resetForeignFilter$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filter => {
      if (filter.type === this.type && filter.value === 'true') {
        this.resetFilterWithoutEvents();
      }
    });
  }

  private setFieldVariants(store = true) {
    const filter = this.listFilter.filter || {};
    const filterValue = [];
    this.items.forEach(item => filterValue.push(item.key));
    const valueString = JSON.stringify(filterValue);

    switch (this.items[0]?.key) {
      case 'true': {
        delete filter[this.field];
        filter[this.fieldVariant] = filterValue;
        store && this.storeFilter(this.fieldVariant, this.items);
        break;
      }
      default: {
        filter[this.fieldVariant]?.[0] === 'true' && delete filter[this.fieldVariant];
        filter[this.field] = filterValue.length > 0 ? valueString : '';
        store && this.storeFilter(this.field, this.items);
      }
    }
    this.listFilter.filter = filter;
  }

  private resetFilterWithoutEvents() {
    if (!this.formField || this.hide || this.items[0]?.key !== 'true') {
      return;
    }

    this.items = [];
    this.excludeKeys = [];
    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.setFieldVariants();
  }
}
