import { Component, DestroyRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ListFilter } from '@parashift/shared/models';
import { FilterService } from '@parashift/shared/services';
import { deepCopy } from '@parashift/shared/utils';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-reset[filter=reset]',
  templateUrl: './reset.component.html',
  standalone: true,
  imports: [NgIf]
})
export class ResetComponent implements OnInit {
  @Input() config: any;
  @Input() listFilter: ListFilter;
  @Input() identifier: string;
  @Input() type: string;
  @Output() onFilterChange: EventEmitter<string>;

  allowMultipleInstances: false;
  showButton = false;

  private frozenListFilter: string;
  private resetInit = true;

  constructor(
    private filterService: FilterService,
    private filterTabsService: FilterTabsService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.frozenListFilter = this.listFilter.initialFilter;
    this.initChangeListener();
  }

  changeFilter(filter) {
    this.onFilterChange.emit(filter);
  }

  initChangeListener() {
    this.filterService.filterEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      delete this.listFilter.initialFilter;
      let currentListFilter: string;

      if (this.resetInit) {
        this.resetInit = false;
        const listFilter = deepCopy(this.listFilter);
        const cleanedUpListFilter = this.filterService.cleanUpListFilter(listFilter);
        currentListFilter = JSON.stringify(cleanedUpListFilter);
      } else {
        const cleanedUpListFilter = this.filterService.cleanUpListFilter(this.listFilter);
        currentListFilter = JSON.stringify(cleanedUpListFilter);
      }

      if (this.frozenListFilter === currentListFilter) {
        // console.log('listFilters equal', this.frozenListFilter, currentListFilter);
        this.listFilter.filterUntouched = true;
        this.showButton = false;
      } else {
        this.listFilter.filterUntouched = false;
        this.showButton = true;
        // console.log('listFilters do not equal', this.frozenListFilter, '###########################', currentListFilter);
      }
    });
  }

  resetFilters() {
    this.filterService.resetFilter();
    this.filterTabsService.resetFilters();
  }
}
