import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ListFilter } from '@parashift/shared/models';
import { FilterMappings } from './../filter.mappings';
import { FilterComponent } from './../filters/filter.component';

@Component({
  selector: 'filter-loader',
  templateUrl: './filter-loader.component.html',
  standalone: true,
  providers: [FilterMappings]
})
export class FilterLoaderComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @Input() type: string;
  @Input() config: any;
  @Input() listFilter: ListFilter;
  @Input() identifier: string;
  @Output() onFilterChange = new EventEmitter<string>();

  private componentRef: ComponentRef<FilterComponent>;

  constructor(private filterMappings: FilterMappings) {}

  ngOnInit() {
    if (this.type) {
      const componentType = this.getComponentType(this.type);
      this.componentRef = this.container.createComponent(componentType);
      this.componentRef.instance.onFilterChange = this.onFilterChange;
      this.componentRef.instance.config = this.config;
      this.componentRef.instance.listFilter = this.listFilter;
      this.componentRef.instance.identifier = this.identifier;
      this.componentRef.instance.type = this.type;
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  private getComponentType(typeName: string) {
    const type = this.filterMappings[typeName];
    return type;
  }
}
