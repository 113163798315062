<div class="form-group" *ngIf="!hide">
  <label class="form-control-label" for="id-search">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.id_search">ID-Search</ng-template>
  </label>
  <input type="id-search"
         id="id-search"
         class="form-control form-control-sm"
         [formControl]="formField"
         [filterAutofocus]="config.autofocus">
</div>
