import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { DocumentType, ListFilter } from '@parashift/shared/models';
import { DocumentTypeService, FilterService, Filter, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-document-type[filter=document-type]',
  templateUrl: './document-type.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, TypeaheadModule]
})
export class DocumentTypeComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = false;
  dataSource: Observable<DocumentType[]>;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    public documentTypeService: DocumentTypeService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef

    ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.initTypeahead();
    const value = this.restoredFilter.value && Array.isArray(this.restoredFilter.value) ? this.restoredFilter.value[1] : undefined;
    this.formField = new UntypedFormControl(value);
  }

  initTypeahead() {
    this.dataSource = new Observable((observer: Observer<DocumentType[]>) => {
      observer.next(this.formField.value);
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.search(term))
    );
  }

  search(term: any): Observable<DocumentType[]> {
    if (!term) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      if (this.listFilter.filter && this.listFilter.filter['document_type_id']) {
        this.resetFilter();
      }
      this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: term });
      return of([]);
    }

    const filters = { discarded: false };

    if (/^[0-9]*$/.test(term)) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      filters['id'] = term;
    } else {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      filters['title_contains'] = term;
    }

    const listFilter = new ListFilter({
      filter: filters,
      fields: {
        document_types: ['title,identifier,tenant_id']
      },
      page: { number: 1, size: 200 },
      sorting: { field: 'title', direction: '' }
    });

    return this.documentTypeService.findAll(listFilter).pipe(map(response => response.getModels()));
  }

  onSelect(event: TypeaheadMatch) {
    this.formField.setValue(event.item.title, { emitEvent: false, emitModelToViewChange: true });
    this.setListFilter(event.item.id);
    this.storeFilter('document_type_id', [event.item.id, event.item.title]);
    this.filterService.emitFilterChange({ type: this.type, value: event.item.title });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter('document_type_id', [filter.value]);
      this.setListFilter(filter.value);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type) {
      const value = filter.value && Array.isArray(filter.value) ? filter.value[1] : undefined;
      this.formField.setValue(value, { emitEvent: false, emitModelToViewChange: true });
      let filters = this.listFilter.filter;
      filters ? (filters.document_type_id = [value]) : (filters = { document_type_id: [value] });
      this.listFilter.filter = filter;
      }
  }

  setListFilter(value) {
    let filter = this.listFilter.filter;
    filter ? (filter.document_type_id = [value]) : (filter = { document_type_id: [value] });
    this.listFilter.filter = filter;
    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.storeFilter('document_type_id', ['']);
    this.setListFilter('');
    this.filterService.emitFilterChange({ type: this.type, value: '' });
  }
}
