<div class="form-group" *ngIf="!hide">
  <label [for]="'selectbox-field-' + config.field" class="form-control-label">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.missing_label">Missing label!</ng-template>
  </label>
  <select [id]="'selectbox-field-' + config.field"
          class="form-control form-control-sm form-select"
          [formControl]="formField">
    <ng-container *ngFor="let option of options$ | async">
      <option *ngIf="!option.group; else optgroup" [ngValue]="option.value" [disabled]="option.disabled">{{ option.label }}</option>
      <ng-template #optgroup>
        <optgroup label="{{ option.group }}">
          <option *ngFor="let child of option.options" [ngValue]="child.value" [disabled]="child.disabled">{{ child.label }}</option>
        </optgroup>
      </ng-template>
    </ng-container>
  </select>
</div>
