import { Component, Input } from '@angular/core';

@Component({
  selector: 'filter-headline[filter=headline]',
  templateUrl: './headline.component.html',
  standalone: true
})
export class HeadlineComponent {
  @Input() config: any;
}
