<div class="form-group" [hidden]="true">
  <label for="sorting-field" class="form-control-label">
    <ng-container *ngIf="config.label; else sort">{{ config.label }}</ng-container><ng-template i18n="@@common.sort" #sort>Sort</ng-template>
  </label>
  <select id="sorting-field"
          class="form-control form-control-sm"
          [formControl]="sortingField">
    <option *ngFor="let option of options" value="{{ option.value }}">
      {{ option.label }}
    </option>
  </select>
</div>

<div class="form-check mb-3" [hidden]="true">
  <input type="checkbox"
         id="sorting-direction"
         class="form-check-input"
         [formControl]="sortingDirection">
  <label class="form-check-label" for="sorting-direction">
    <ng-container i18n="@@common.filter_sorting.descending_order">Descending order</ng-container>
  </label>
</div>
