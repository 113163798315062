import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-group-by[filter=group-by]',
  templateUrl: './group-by.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class GroupByComponent extends FilterComponent {
  formField: UntypedFormControl;
  options: { label: string; value: string; }[];
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.formField = new UntypedFormControl(this.restoredFilter.value || '');
    this.options = this.config.options;
    this.storeFilter('group_by', this.formField.value);
    this.listFilter.group_by = this.formField.value;

    this.formField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: string) => {
      this.setListFilter(value);
      this.storeFilter('group_by', value);
      this.filterService.emitFilterChange({ type: this.type, value });
    });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter('group_by', filter.value);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.value) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.listFilter.group_by = filter.value;
      this.storeFilter('group_by', this.listFilter.group_by);
    }
  }

  setListFilter(value) {
    this.listFilter.group_by = value;
    this.changeFilter(this.listFilter);
    this.filterService.groupBy(value);
  }

  resetFilter() {
    const value = this.config.defaultValue || '';
    this.setListFilter(value);
    this.storeFilter('group_by', value);
    this.filterService.emitFilterChange({ type: this.type, value });
  }
}
