import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'markAnyAndNoneOptions',
  standalone: true
})
export class MarkAnyAndNoneOptionsPipe implements PipeTransform {
  transform(value: string): string {
    return value === 'any' || value === 'none' ? `<${value}>` : value;
  }
}
