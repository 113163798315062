<div class="input-group filter-sorting" data-cy="filter-sorting-group">
  <select id="filter-sorting-field"
          class="form-select form-select-sm"
          style="display: inline-block"
          [formControl]="filterSortingField">
    <option *ngFor="let option of options" [ngValue]="option.value">{{ option.label }}</option>
  </select>
  <button class="btn btn-light btn-sm ps-2 pe-2"
          type="button"
          (click)="toggleDirection()">
    <fa-icon [icon]="['fas', 'sort-amount-down']"
              *ngIf="filterSortingDirection"
    ></fa-icon>
    <fa-icon [icon]="['fas', 'sort-amount-down-alt']"
              *ngIf="!filterSortingDirection"
    ></fa-icon>
  </button>
</div>
