import { Component, DestroyRef, Inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor, NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ListFilter } from '@parashift/shared/models';
import { Filter, FilterService, RestoreFilterParams } from '@parashift/shared/services';
import { FilterTabsService } from '../filter-tabs/filter-tabs.service';
import { deepCopy } from '@parashift/shared/utils';

export interface Options {
  label: string;
  value: any;
}

@Component({
  selector: 'filter-sorting',
  templateUrl: './filter-sorting.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgFor, NgIf, FaIconComponent]
})
export class FilterSortingComponent implements OnInit {
  @Input() filterConfig;
  @Input() listFilter: ListFilter;

  private restoredFilter: any = { field: '', value: '' };

  config;
  type = 'sorting';
  options: Options[];
  allowMultipleInstances = false;
  restoreFilterParams: RestoreFilterParams;
  filterSortingField: UntypedFormControl;
  filterSortingDirection: string | boolean;
  field: string;

  constructor(
    private route: ActivatedRoute,
    private filterService: FilterService,
    private filterTabsService: FilterTabsService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.initFilterListener();
    this.getConfig();
    this.setParams();
    this.restoreFilter();
    this.initFilter();
    this.initFilterTabListener();
  }

  getConfig() {
    let config;

    if (this.filterConfig && Array.isArray(this.filterConfig.filters)) {
      config = this.filterConfig.filters.find(obj => {
        return obj.type === this.type;
      });

      if (config) {
        this.config = config.config;
        this.options = deepCopy(this.config.options);
      }
    }
  }

  initFilter() {
    const initialFilter = this.getInitalValues();
    this.filterSortingField = new UntypedFormControl(initialFilter.field);
    this.filterSortingDirection = initialFilter.direction;

    if (this.listFilter.sorting) {
      this.listFilter.sorting.direction = initialFilter.direction ? '-' : '';
      this.listFilter.sorting.field = initialFilter.field;
    } else {
      this.listFilter.sorting = {
        direction: initialFilter.direction ? '-' : '',
        field: initialFilter.field
      };
    }

    this.filterSortingField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((field: string) => {
      this.field = field;
      this.setActiveOptionPrefix();
      this.filterService.setFilter({ type: this.type, field: 'field', value: field });
    });
  }

  setActiveOptionPrefix() {
    this.options = deepCopy(this.config.options);
    this.options.map(option => {
      if (option.value === this.field) {
        option.label = $localize `:@@shared.filter_column.sorted_by:Sorted by:` + ' ' + option.label;
      }
    });
  }

  toggleDirection() {
    if (this.filterSortingDirection) {
      this.filterSortingDirection = '';
    } else {
      this.filterSortingDirection = '-';
    }

    this.filterService.setFilter({ type: this.type, field: 'direction', value: this.filterSortingDirection });
  }

  initFilterListener() {
    this.filterService.filterChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filter => {
      this.setFilter(filter);
    });
  }

  initFilterTabListener() {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const filter = this.filterTabsService.getUserDefinedFilter(this.type, this.config.field, false);
      this.setFilterFromTab(filter);
    });
  }

  setFilter(filter) {
    if (filter.type === this.type && filter.field === 'field') {
      this.filterSortingField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.field = filter.value;
      this.setActiveOptionPrefix();
    } else if (filter.type === this.type && filter.field === 'direction') {
      this.filterSortingDirection = filter.value;
    }
  }

  setFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.value) {
      const values = this.getUserFilterValues(filter.value);
      this.filterSortingField.setValue(values.field, { emitEvent: false, emitModelToViewChange: true });
      this.filterSortingDirection = values.direction;
      if (this.listFilter.sorting) {
        this.listFilter.sorting.direction = values.direction ? '-' : '';
        this.listFilter.sorting.field = values.field;
      } else {
        this.listFilter.sorting = {
          direction: values.direction ? '-' : '',
          field: values.field
        };
      }
    }
  }

  getInitalValues() {
    const restoredValue = this.restoredFilter.value;
    let direction: string | boolean;
    let field = '';

    if (restoredValue) {
      direction = restoredValue.substr(0, 1);
      field = restoredValue;

      if (direction === '-') {
        field = restoredValue.substr(1);
        direction = true;
      } else {
        direction = false;
      }
    } else {
      field = this.config.defaultValues.field;
      direction = this.config.defaultValues.desc;
    }

    this.field = field;
    this.setActiveOptionPrefix();
    return { direction, field };
  }

  getUserFilterValues(value: string) {
    let direction: string | boolean;
    let field = '';

    if (value) {
      direction = value.substring(0, 1);
      field = value;

      if (direction === '-') {
        field = value.substring(1);
        direction = true;
      } else {
        direction = false;
      }
    } else {
      field = this.config.defaultValues.field;
      direction = this.config.defaultValues.desc;
    }

    this.field = field;
    this.setActiveOptionPrefix();
    return { direction, field };
  }

  setParams() {
    this.restoreFilterParams = {
      allowMultipleInstances: this.allowMultipleInstances,
      identifier: this.filterConfig.meta.identifier,
      filterType: this.type,
      field: undefined
    };
  }

  restoreFilter() {
    this.restoredFilter = this.filterService.restoreFilter(this.restoreFilterParams);
  }
}
