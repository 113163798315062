<div class="form-group" *ngIf="!hide">
  <label for="global-roles-field" class="form-control-label">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@common.parashift_roles">Parashift Roles</ng-template>
  </label>
  <select id="global-roles-field"
          class="form-control form-control-sm form-select"
          [formControl]="formField">
    <option *ngFor="let option of options" value="{{ option.value }}">{{ option.label }}</option>
  </select>
</div>
