import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ColumnsService } from './../columns.service';

@Component({
  selector: 'left-column',
  templateUrl: './left-column.component.html',
  animations: [
    trigger('toggleLeftColumn', [
      state('false', style({ height: '0px', width: '0', 'padding-left': '0px', 'padding-right': '0px' })),
      state('true', style({ height: '*', width: '*', 'padding-left': '12px', 'padding-right': '10px' })),
      transition('false <=> true', animate('350ms ease-in-out'))
    ])
  ],
  standalone: true
})
export class LeftColumnComponent implements OnDestroy, OnInit {
  @Input() identifiers: string[];
  @Input() icons: string[];
  @HostBinding('@toggleLeftColumn') toggleLeftColumn = false;

  toggleLeftColumnSubscription: Subscription;
  showLeftSubscription: Subscription;
  show = '';

  constructor(private columnsService: ColumnsService) {
    this.columnsService.showLeftToggle = true;
    this.toggleLeftColumnSubscription = this.columnsService.toggleLeftColumn$.subscribe(toggle => {
      this.toggleLeftColumn = toggle;
    });

    this.showLeftSubscription = this.columnsService.showLeft$.subscribe(identifier => {
      this.show = identifier;
    });
  }

  ngOnInit() {
    this.columnsService.identifiersLeft = this.identifiers;
    this.columnsService.iconsLeft = this.icons;
    this.columnsService.restoreLeftColumnState();
  }

  ngOnDestroy() {
    this.columnsService.identifiersLeft = [];
    this.columnsService.iconsLeft = [];
    this.columnsService.toggleLeftColumn(false, '');
    this.columnsService.showLeftToggle = false;
    this.columnsService.toggleLeftModel = null;
    this.toggleLeftColumnSubscription.unsubscribe();
    this.showLeftSubscription.unsubscribe();
  }
}
