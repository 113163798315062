<ng-template #filterDocumentTypeItemTemplate let-item="item">
  #{{ item.id }} {{ item.title }} <span *ngIf="item.tenant_id">(<ng-container i18n="@@common.tenant">Tenant</ng-container>: {{ item.tenant_id }})</span>
</ng-template>

<div class="form-group" *ngIf="!hide">
  <label class="form-control-label" for="filter_document_type">
    <ng-container *ngIf="config.label; else documentType">{{ config.label }}</ng-container><ng-template i18n="@@common.document_type" #documentType>Document Type</ng-template>
  </label>
  <input
    class="form-control from-control-sm"
    type="text"
    id="filter_document_type"
    [formControl]="formField"
    [typeahead]="dataSource"
    [typeaheadAsync]="true"
    [typeaheadOptionsLimit]="20"
    [typeaheadScrollable]="true"
    [typeaheadOptionsInScrollableView]="10"
    [typeaheadMinLength]="0"
    [typeaheadLatinize]="true"
    [typeaheadItemTemplate]="filterDocumentTypeItemTemplate"
    (typeaheadOnSelect)="onSelect($event)"
    container="body">
</div>
