import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FilterComponent } from './../filter.component';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { FilterAutofocusDirective } from '../../directives/filter-autofocus.directive';

@Component({
  selector: 'filter-id-search[filter=id-search]',
  templateUrl: './id-search.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FilterAutofocusDirective]
})
export class IdSearchComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.formField = new UntypedFormControl(this.restoredFilter.value);
    this.formField.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(400)
      )
      .subscribe(id => {
        // Remove whitespaces and non-digits in case someone enters comma-sparated values
        id = id.replace(/\s+/g, '');
        id = id.split(',').map(t => t.replace(/[^0-9]+/g, '')).filter(t => t).join(',');
        this.setListFilter(id);
        this.storeFilter('id', id ? [id] : []);
        this.filterService.emitFilterChange({ type: this.type, value: id });
        id === '' && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: id });
      });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter('id', [filter.value]);
      this.setListFilter(filter.value);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      let filters = this.listFilter.filter;
      filters ? (filters.id = filter.value) : (filters = { id: filter.value });
      this.listFilter.filter = filters;
    }
  }

  setListFilter(value) {
    let filter = this.listFilter.filter;
    filter ? (filter.id = [value]) : (filter = { id: [value] });
    this.listFilter.filter = filter;
    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.storeFilter('id', ['']);
    this.setListFilter('');
    this.filterService.emitFilterChange({ type: this.type, value: '' });
  }
}
