<div class="form-group" [hidden]="true">
  <label for="group-by" class="form-control-label">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.missing_label">Missing label!</ng-template>
  </label>
  <select id="group-by"
          class="form-control form-control-sm"
          [formControl]="formField">
    <option value="" i18n="@@common.group_by">Group by</option>
    <option *ngFor="let option of options" [ngValue]="option.value">{{ option.label }}</option>
  </select>
</div>
