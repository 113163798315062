import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { getSelectboxOptionsFromConstants } from '@parashift/shared/utils';
import { ParashiftRoles } from '@parashift/shared/constants';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-global-roles[filter=global-roles]',
  templateUrl: './global-roles.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class GlobalRolesComponent extends FilterComponent {
  formField: FormControl;
  options: { label: string; value: string; }[];
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    const initialFilter = this.getInitalValue();
    this.formField = new FormControl(initialFilter.field);
    this.options = getSelectboxOptionsFromConstants(ParashiftRoles, true, $localize `:@@common.not_set:Not set`),

    this.formField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((option: any) => {
      this.setListFilter(option);
      this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: option });
      option === '' && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: option });
    });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.setListFilter(filter.value);
    }
  }

   setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      const value = filter.value[0] ? [filter.value[0]] : [''];
      this.formField.setValue(value, { emitEvent: false, emitModelToViewChange: true });
      const filters = this.listFilter.filter || {};
      filters[this.config.field] = value;
      this.listFilter.filter = filters;
      }
  }

  setListFilter(option) {
    const filter = this.listFilter.filter || {};
    filter[this.config.field] = [option];
    this.listFilter.filter = filter;
    this.storeFilter(this.config.field, [option]);
    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.setListFilter('');
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: '' });
  }

  getInitalValue() {
    const restoredValue = this.restoredFilter.value;
    let field = '';

    if (restoredValue[0]) {
      field = restoredValue[0];
    } else {
      field = '';
    }

    return { field: [field] };
  }
}
