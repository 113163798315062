import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { BsLocaleService, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { format } from 'date-fns-tz';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { parseDateString } from '@parashift/shared/utils';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-date-range[filter=date-range]',
  templateUrl: './date-range.component.html',
  standalone: true,
  imports: [NgIf, BsDatepickerModule, ReactiveFormsModule]
})
export class DateRangeComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = true;
  initialValue = [];

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    private localeService: BsLocaleService,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
    this.localeService.use(this.sessionService.user.language);
  }

  initFilter() {
    this.initDefaultValue();

    let initialDateRange = this.formatRestoredDates(this.restoredFilter.value[0]);
    if (!initialDateRange || (Array.isArray(initialDateRange) && initialDateRange.length === 0)) {
      initialDateRange = this.initialValue;
    }

    this.formField = new UntypedFormControl(initialDateRange);
    this.initialValue = initialDateRange;

    this.formField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(range => {
      this.setListFilter(range);
      this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: range });
      (!range || (Array.isArray(range) && range.length === 1))
      && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: range });
    });
  }

  private initDefaultValue() {
    const { defaultValue } = this.config;
    if (!defaultValue) {
      return;
    }
    this.initialValue = defaultValue;
    this.setListFilter(defaultValue);
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: defaultValue });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter(this.config.field, filter.value);
      this.setListFilter(filter.value);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      const initialDateRange = this.formatRestoredDates(filter.value[0]);
      this.formField.setValue(initialDateRange, { emitEvent: false, emitModelToViewChange: true });

      if (!this.listFilter.filter) {
        this.listFilter.filter = {};
      }

      const filters = this.listFilter.filter;
      filters[this.config.field + '_range'] = [filter.value[0]];
      this.listFilter.filter = filters;
    }
  }

  setListFilter(range) {
    if (
      range &&
      range.length === 2 &&
      Object.prototype.toString.call(range[0]) === '[object Date]' &&
      Object.prototype.toString.call(range[1]) === '[object Date]'
    ) {
      const dateRange = format(range[0], 'dd-MM-yyyy') + '..' + format(range[1], 'dd-MM-yyyy');

      if (!this.listFilter.filter) {
        this.listFilter.filter = {};
      }

      const filter = this.listFilter.filter;
      filter[this.config.field + '_range'] = [dateRange];
      this.listFilter.filter = filter;
      this.storeFilter(this.config.field, [dateRange]);
    } else {
      if (!this.listFilter.filter) {
        this.listFilter.filter = {};
      }

      const filter = this.listFilter.filter;
      filter[this.config.field + '_range'] = [];
      this.listFilter.filter = filter;
      this.storeFilter(this.config.field, []);
    }

    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    const value = this.config.defaultValue || [];
    this.formField.setValue(value, { emitEvent: false, emitModelToViewChange: true });
    this.storeFilter(this.config.field, value);
    this.setListFilter(value);
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value });
  }

  formatRestoredDates(dates) {
    if (dates) {
      const dateRange = [];
      dates = dates.split('..');
      dateRange.push(parseDateString(dates[0], 'dd-MM-yyyy'));
      dateRange.push(parseDateString(dates[1], 'dd-MM-yyyy'));

      return dateRange;
    }

    return [];
  }
}
