import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass, NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

@Component({
  selector: 'filter-checkbox[filter=checkbox]',
  templateUrl: './checkbox.component.html',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, NgIf]
})
export class CheckboxComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = true;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.formField = new UntypedFormControl();

    this.formField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((checked: boolean) => {
      this.setListFilter(checked);
      this.filterService.emitFilterChange({ type: this.type, field: this.config.filter, value: this.config.value, checked });
    });

    const valueIndex = this.findFilterValue(this.restoredFilter.value, this.config.value);

    if (this.config.checked || valueIndex > -1) {
      this.formField.setValue(true, { emitEvent: false, emitModelToViewChange: false });
    }
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.filter && filter.value === this.config.value) {
      this.formField.setValue(filter.checked, { emitEvent: false, emitModelToViewChange: true });
      this.setListFilter(filter.checked);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      const valueIndex = this.findFilterValue(filter.value, this.config.value);

      if (valueIndex > -1) {
        this.formField.setValue(true, { emitEvent: false, emitModelToViewChange: false });
        this.checkFilter();
      }
    }
  }

  setListFilter(checked) {
    checked ? this.checkFilter() : this.uncheckFilter();
    this.changeFilter(this.listFilter);
  }

  resetFilter() {
    const checked = this.config.checked || false;
    this.formField.setValue(checked, { emitEvent: false, emitModelToViewChange: true });
    this.setListFilter(checked);
    this.filterService.emitFilterChange({ type: this.type, field: this.config.filter, value: this.config.value, checked });
  }

  checkFilter() {
    let filter = {};

    if (!this.listFilter.filter) {
      filter[this.config.filter] = [this.config.value];
      this.listFilter.filter = filter;
    } else if (this.listFilter.filter && !this.listFilter.filter[this.config.filter]) {
      filter = this.listFilter.filter;
      filter[this.config.filter] = [this.config.value];
      this.listFilter.filter = filter;
    } else {
      const valueIndex = this.findFilterValue(this.listFilter.filter[this.config.filter], this.config.value);

      if (valueIndex < 0) {
        filter = this.listFilter.filter;
        filter[this.config.filter].push(this.config.value);
        this.listFilter.filter = filter;
      }
    }

    this.storeFilter(this.config.filter, this.listFilter.filter[this.config.filter]);
  }

  uncheckFilter() {
    if (this.listFilter.filter && this.listFilter.filter[this.config.filter] && this.listFilter.filter[this.config.filter].length > 0) {
      const valueIndex = this.findFilterValue(this.listFilter.filter[this.config.filter], this.config.value);
      const filter = this.listFilter.filter;

      if (valueIndex > -1) {
        filter[this.config.filter].splice(valueIndex, 1);
        this.listFilter.filter = filter;
      }
    }

    this.storeFilter(this.config.filter, this.listFilter.filter[this.config.filter]);
  }

  findFilterValue(filterValues, search) {
    return filterValues.indexOf(search);
  }
}
