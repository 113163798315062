<div class="form-group" *ngIf="!hide">
  <label class="form-control-label" [for]="'search_' + config.field">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.search">Search</ng-template>
  </label>
  <input type="text"
         [id]="'search_' + config.field"
         class="form-control form-control-sm"
         [formControl]="formField"
         [filterAutofocus]="config.autofocus">
</div>
