<div class="form-group" *ngIf="!hide">
  <label [for]="'date-range-field-' + config.field" class="form-control-label">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.missing_label">Missing label!</ng-template>
  </label>
  <input class="form-control form-control-sm"
         type="text"
         bsDaterangepicker
         placement="right"
         [bsConfig]="{ dateInputFormat: 'DD.MM.YYYYY', containerClass: 'theme-dark-blue' }"
         triggers="keydown click"
         [id]="'date-range-field-' + config.field"
         [formControl]="formField">
</div>
