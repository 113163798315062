<div class="form-group" *ngIf="!hide">
  <label class="form-control-label">
    <ng-container *ngIf="config.label; else default">{{ config.label }}</ng-container><ng-template #default i18n="@@shared.filter_column.missing_label">Missing label!</ng-template>
  </label>

  <div class="pp-slider">
    <ngx-slider [formControl]="formField" [options]="sliderOptions"></ngx-slider>
  </div>

</div>
