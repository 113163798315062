import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ColumnsService } from 'shared/components/columns/columns.service';

@Directive({
  selector: '[filterAutofocus]',
  standalone: true
})
export class FilterAutofocusDirective implements OnDestroy {
  @Input('filterAutofocus') autofocus: boolean;
  toggleLeftColumnSubscription: Subscription;

  constructor(private element: ElementRef, private columnsService: ColumnsService) {
    this.toggleLeftColumnSubscription = this.columnsService.toggleLeftColumn$.subscribe(toggle => {
      if (toggle && this.autofocus) {
        this.element.nativeElement.focus();
      }
    });
  }

  ngOnDestroy() {
    this.toggleLeftColumnSubscription.unsubscribe();
  }
}
