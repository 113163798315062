import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { pairwise, startWith } from 'rxjs/operators';
import { Options, NgxSliderModule } from '@angular-slider/ngx-slider';
import { FilterComponent } from 'shared/components/filter-column/filters/filter.component';
import { Filter, FilterService } from '@parashift/shared/services';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { SessionService } from '@parashift/shared/services';
import { Logger } from '@parashift/shared/services';

@Component({
  selector: 'filter-number-range[filter=number-range]',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss'],
  standalone: true,
  imports: [NgIf, NgxSliderModule, ReactiveFormsModule]
})
export class NumberRangeComponent extends FilterComponent {

  formField: UntypedFormControl;
  allowMultipleInstances = true;
  initialValue = [];

  readonly notSet = {
    value: undefined,
    label: $localize `:@@shared.filter_column.not_set:Not set`,
    range: (): [number, number] => [this.notSet.value, this.notSet.value]
  };

  readonly defaultSliderOptions: Options = {
    translate: (value: number) => {
      if (value === this.notSet.value) {
        return this.notSet.label;
      }
      return '' + value;
    },
    combineLabels: (minLabel, maxLabel) => {
      if (minLabel === this.notSet.label || maxLabel === this.notSet.label) {
        return this.notSet.label;
      }
      if (minLabel === maxLabel) {
        return minLabel;
      }
      return `${minLabel} - ${maxLabel}`;
    }
  };

  sliderOptions;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter(): void {
    this.sliderOptions = {
      ...this.defaultSliderOptions,
      floor: this.config.options.floor - 1,
      ceil: this.config.options.ceil
    };
    this.notSet.value = this.sliderOptions.floor;

    const initialNumberRange = this.formatRestoredNumbers(this.restoredFilter.value) || this.notSet.range();
    this.formField = new UntypedFormControl(initialNumberRange);
    this.initialValue = initialNumberRange;

    this.formField.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.initialValue),
        pairwise()
      )
      .subscribe(([prev, next]) => {
        if (this.isIndeterminateRange(next)) {
          next = this.isIndeterminateRange(prev)
            ? this.completeRange(next)
            : this.notSet.range();
          this.formField.setValue(next, { emitEvent: false, emitModelToViewChange: true });
        }
        this.setListFilter(next);
        this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: this.getSanitizedRangeFilter(next) });
        !this.isValidRange(next) && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: next });

      });
  }

  setFilter(filter: Filter): void {
    if (filter.type === this.type && filter.field === this.config.field) {
      const filterValue = filter.value === '' ? this.notSet.range() : filter.value;
      this.formField.setValue(filterValue, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter(this.config.field, filter.value);
      this.setListFilter(filterValue);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      const filterValue = this.formatRestoredNumbers(filter.value) || this.notSet.range();
      this.formField.setValue(filterValue, { emitEvent: false, emitModelToViewChange: true });

      if (!this.listFilter.filter) {
        this.listFilter.filter = {};
      }

      const filters = this.listFilter.filter;
      filters[this.config.field + '_range'] = [filter.value[0]];
      this.listFilter.filter = filters;
    }
  }

  setListFilter(range): void {
    if (!this.listFilter.filter) {
      this.listFilter.filter = {};
    }
    const filter = this.listFilter.filter;
    const filterValue = this.getFilterValue(range);
    if (filter[this.config.field + '_range'] === filterValue) {
      return;
    }
    filter[this.config.field + '_range'] = filterValue;
    this.listFilter.filter = filter;
    this.storeFilter(this.config.field, filterValue);
    this.changeFilter(this.listFilter);
  }

  isValidRange(range) {
    return range
      && range.length === 2
      && Object.prototype.toString.call(range[0]) === '[object Number]'
      && Object.prototype.toString.call(range[0]) === '[object Number]';
  }

  getFilterValue(range) {
    if (!this.isValidRange(range)) {
      return [];
    }
    if (this.rangeEquals(range, this.notSet.range())) {
      return [];
    }
    if (this.isIndeterminateRange(range)) {
      throw new Error('Cannot determine value of indeterminate range.');
    }
    const [from, to] = range;
    return [`${from}..${to}`];
  }

  getSanitizedRangeFilter(range) {
    if (!this.isValidRange(range)) {
      throw new Error('Cannot sanitize invalid range.');
    }
    if (this.rangeEquals(range, this.notSet.range())) {
      return [];
    }
    if (this.isIndeterminateRange(range)) {
      return this.completeRange(range);
    }
    return range;
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.formField.setValue(this.notSet.range(), { emitEvent: false, emitModelToViewChange: true });
    this.storeFilter(this.config.field, []);
    this.setListFilter('');
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: [] });
  }

  formatRestoredNumbers(numbers: [string]) {
    if (numbers && numbers.length === 1) {
      return numbers[0].split('..');
    }
    return undefined;
  }

  isIndeterminateRange([from, to]): boolean {
    return from === this.notSet.value || to === this.notSet.value;
  }

  rangeEquals(left: [number, number], right: [number, number]): boolean {
    return left[0] === right[0] && left[1] === right[1];
  }

  completeRange(range: [number, number]): [number, number] {
    const completed = [];
    if (range[0] === this.notSet.value) {
      completed.push(range[1]);
    } else {
      completed.push(range[0]);
    }
    if (range[1] === this.notSet.value) {
      completed.push(range[0]);
    } else {
      completed.push(range[1]);
    }
    return completed as [number, number];
  }
}
