import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { QueryParams } from '@parashift/shared/models';
import { FilterService, Filter, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

export interface Option {
  value: string;
  label: any;
  disabled?: boolean;
  group?: string;
}

export interface OptionGroup {
  group: string;
  options: Option[];
}

export type IterableOptions = Array<Option & OptionGroup>;

@Component({
  selector: 'filter-selectbox[filter=selectbox]',
  templateUrl: './selectbox.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor, AsyncPipe]
})
export class SelectboxComponent extends FilterComponent {
  formField: UntypedFormControl;
  options$: Observable<IterableOptions>;
  allowMultipleInstances = true;

  param_type: 'filter' | 'query_params' = 'filter';

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.param_type = this.config.param_type || this.param_type;
    this.formField = new UntypedFormControl(this.restoredFilter.value[0]);
    this.options$ = this.checkForOptionGroups();

    if (this.config.defaultValue) {
      this.formField.setValue(this.config.defaultValue);
      this.setFieldVariants(this.config.field, this.config.defaultValue);
      this.setListFilter();
      this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: this.config.defaultValue });
    }

    this.formField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((option: any) => {
      this.setFieldVariants(this.config.field, option);
      this.setListFilter();
      this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: option });
      option === '' && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: option });
    });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.setFieldVariants(this.config.field, filter.value);
      this.setListFilter();
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field && filter?.value[0]) {
      this.formField.setValue(filter.value[0], { emitEvent: false, emitModelToViewChange: true });
      this.setFieldVariants(this.config.field, filter.value[0], false);
    }
  }

  setListFilter() {
    this.changeFilter(this.listFilter);
  }

  setFieldVariants(field, value, store = true) {
    value = value || '';
    const variant = value.split('_');
    let result = [];

    const filter = this.listFilter[this.param_type] || {};
    delete filter[field];

    switch (variant[0]) {
      case 'blank': {
        result = [field + '_' + 'blank', variant[1]];
        break;
      }
      default: {
        result = [field, value];
        delete this.listFilter[this.param_type][field + '_blank'];
      }
    }

    filter[result[0]] = result[1] ? [result[1]] : [];
    store && this.storeFilter(field, result[1] ? [value] : []);
    if (this.param_type === 'filter') {
      this.listFilter.filter = filter;
    } else {
      this.listFilter.query_params = filter as QueryParams;
    }
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    const value = this.config.defaultValue || '';
    this.formField.setValue(value, { emitEvent: false, emitModelToViewChange: true });
    this.setFieldVariants(this.config.field, value);
    this.setListFilter();
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value });
  }

  private checkForOptionGroups(): Observable<IterableOptions> {
    if (this.config.options instanceof Observable) {
        return new Observable((observer: Observer<IterableOptions>) => {
          this.config.options.subscribe(options => {
            observer.next(this.buildOptionGroups(options));
          });
        }).pipe(takeUntilDestroyed(this.destroyRef));
    } else {
      return new Observable((observer: Observer<IterableOptions>) => {
        observer.next(this.buildOptionGroups(this.config.options));
      }).pipe(takeUntilDestroyed(this.destroyRef));
    }
  }

  private buildOptionGroups(options: Option[]): IterableOptions {
    const optionGroups = [];
    let lastGroup = '';
    let group: OptionGroup;

    options.forEach((option: Option) => {
      if (option.group) {
        if (option.group !== lastGroup) {
          if (group) {
            optionGroups.push(group);
          }
          group = { group: option.group, options: [] };
          lastGroup = option.group;
        }
        group.options.push({ value: option.value, label: option.label, disabled: option.disabled || false } as Option);
      } else {
        optionGroups.push({ value: option.value, label: option.label, disabled: option.disabled || false } as Option);
      }
    });

    if (group) {
      optionGroups.push(group);
    }

    return optionGroups;
  }
}
