<div class="form-group" *ngIf="!hide">
  <label class="form-control-label" [for]="'filter_multiselect_typeahead_' + config.field">
    {{ config.label }}
  </label>
  <input
    class="form-control from-control-sm"
    type="text"
    [id]="'filter_multiselect_typeahead_' + config.field"
    [formControl]="formField"
    [typeahead]="dataSource"
    [typeaheadAsync]="true"
    [typeaheadOptionsLimit]="20"
    [typeaheadScrollable]="true"
    [typeaheadOptionsInScrollableView]="10"
    [typeaheadMinLength]="0"
    [typeaheadLatinize]="true"
    [typeaheadOptionField]="'search_index'"
    [filterAutofocus]="config.autofocus"
    (typeaheadOnSelect)="onSelect($event)"
    container="body">
</div>

<div *ngIf="items && items.length > 0" class="mt-2">
  <ng-template ngFor let-item [ngForOf]="items" let-i="index">
    <span *ngIf="item.tooltip; else noTooltip" class="badge-item me-2 mb-2" tooltip="{{ item.tooltip }}">
      <fa-icon [icon]="['fas', config.icon || 'circle']"
               [fixedWidth]="true"
               class="me-1">
      </fa-icon>{{ item.value | slice:0:(config.sliceValue ? config.sliceValue : 200) }}<span *ngIf="config.sliceValue && item.value.length > (config.sliceValue || 200)">...</span><span (click)="unlinkItem(i)" class="times pointer ms-1">&times;</span>
    </span>

    <ng-template #noTooltip>
      <span class="badge-item me-2 mb-2">
        <fa-icon [icon]="['fas', config.icon || 'circle']"
                 [fixedWidth]="true"
                 class="me-1">
        </fa-icon>{{ item.value | slice:0:(config.sliceValue ? config.sliceValue : 200) }}<span *ngIf="config.sliceValue && item.value.length > (config.sliceValue || 200)">...</span><span (click)="unlinkItem(i)" class="times pointer ms-1">&times;</span>
      </span>
    </ng-template>
  </ng-template>
</div>
