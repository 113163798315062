import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, Observer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { FilterAutofocusDirective } from '../../directives/filter-autofocus.directive';

export interface SourceItem {
  key: string;
  value: string;
  search_index: string;
  tooltip?: string;
}

@Component({
  selector: 'filter-multiselect-typeahead[filter=multiselect-typeahead]',
  templateUrl: './multiselect-typeahead.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, TypeaheadModule, FilterAutofocusDirective, NgFor, TooltipModule, FaIconComponent, SlicePipe]
})
export class MultiselectTypeaheadComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = true;
  dataSource: Observable<SourceItem[]>;
  source: ((term: string, excludeKeys: string[]) => Observable<SourceItem[]>) | SourceItem[];
  items = [];
  excludeKeys = [];

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.initTypeahead();
    this.formField = new UntypedFormControl();
    this.items = this.restoredFilter.value && Array.isArray(this.restoredFilter.value) ? this.restoredFilter.value : [];
    this.source = this.config.source;
    this.initExcludedKeys();
  }

  initTypeahead() {
    this.dataSource = new Observable((observer: Observer<SourceItem[]>) => {
      observer.next(this.formField.value);
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.search(term))
    );
  }

  private initExcludedKeys() {
    this.excludeKeys = [];
    this.items.forEach(item => this.excludeKeys.push(item.key));
  }

  search(term: any): Observable<SourceItem[]> {
    if (!term) {
      return of([]);
    }

    if (typeof this.source === 'function') {
      return this.source(term, this.excludeKeys);
    } else {
      const query = new RegExp(term, 'ig');
      const result = this.source.filter((item: any) => query.test(item.search_index));
      return of(this.removeSelectedItems(result));
    }
  }

  onSelect(event: TypeaheadMatch) {
    if (this.config.convertToNumber) {
      this.items.push({
        ...event.item,
        key: Number(event.item.key)
      });
    } else {
      this.items.push(event.item);
    }
    this.excludeKeys.push(event.item.key);
    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.setListFilter();
    this.storeFilter(this.config.field, this.items);
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: this.items });
    this.items.length === 0 && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: this.items });
  }

  unlinkItem(index: number) {
    this.items.splice(index, 1);
    this.excludeKeys.splice(index, 1);
    this.setListFilter();
    this.storeFilter(this.config.field, this.items);
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: this.items });
    this.items.length === 0 && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: this.items });
  }

  private removeSelectedItems(source) {
    const result = [];
    source.forEach((sourceItem) => {
      if (!this.items || !this.items.find(item => item.key === sourceItem.key)) {
        result.push(sourceItem);
      }
    });

    return result;
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      if (!filter.value) {
        this.items = [];
        this.excludeKeys = [];
      }

      this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter(this.config.field, filter.value);
      this.setListFilter();
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      if (!filter.value) {
        this.items = [];
        this.excludeKeys = [];
      } else {
        this.items = Array.isArray(filter.value) ? filter.value : [];
        this.excludeKeys = [];
        this.items.forEach(item => this.excludeKeys.push(item.key));
      }
      this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
      const filters = this.listFilter.filter || {};
      const filterValue = [];
      this.items.forEach(item => filterValue.push(item.key));
      const valueString = JSON.stringify(filterValue);
      filters[this.config.field] = filterValue.length > 0 ? valueString : '';
      this.listFilter.filter = filters;
    }
  }

  setListFilter() {
    const filter = this.listFilter.filter || {};
    const filterValue = [];
    this.items.forEach(item => filterValue.push(this.config.convertToNumber ? Number(item.key) : item.key));

    const valueString = JSON.stringify(filterValue);
    filter[this.config.field] = filterValue.length > 0 ? valueString : '';
    this.listFilter.filter = filter;
    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.items = [];
    this.excludeKeys = [];
    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.storeFilter(this.config.field, '');
    this.setListFilter();
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: '' });
  }
}
