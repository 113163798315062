import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';
import { FilterAutofocusDirective } from '../../directives/filter-autofocus.directive';

@Component({
  selector: 'filter-search[filter=search]',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FilterAutofocusDirective]
})
export class SearchComponent extends FilterComponent {
  formField: UntypedFormControl;
  allowMultipleInstances = true;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  initFilter() {
    this.formField = new UntypedFormControl(this.restoredFilter.value);
    this.formField.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(400)
      )
      .subscribe(term => {
        if (this.config.removeWhitespaces) {
          term = term.replace(/\s+/g, '');
        }
        if (this.config.numbersOnly) {
          term = term.split(',').map(t => t.replace(/[^0-9]+/g, '')).filter(t => t).join(',');
        }
        this.storeFilter(this.config.field, term);
        this.setListFilter(term);
        this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: term });
        term === '' && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: term });
      });
  }

  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.storeFilter(this.config.field, filter.value);
      this.setListFilter(filter.value);
    }
  }

  setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });

      if (this.config.plain) {
        this.listFilter.ocr_text_contains = filter.value;
      } else {
        const filters = this.listFilter.filter || {};
        filters[this.config.field] = [filter.value];
        this.listFilter.filter = filters;
      }
    }
  }

  setListFilter(value) {
    if (this.config.plain) {
      this.listFilter.ocr_text_contains = value;
    } else {
      const filter = this.listFilter.filter || {};
      filter[this.config.field] = [value];
      this.listFilter.filter = filter;
    }
    this.changeFilter(this.listFilter);
  }

  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.storeFilter(this.config.field, '');
    this.setListFilter('');
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: '' });
  }
}
