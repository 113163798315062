<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
  <div class="tags-dropdown">
    <ng-container *ngFor="let match of matches">
      <button
        class="dropdown-item"
        [class.active]="typeaheadTemplateMethods.isActive(match)"
        (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
        (click)="typeaheadTemplateMethods.selectMatch(match, $event)">
        <span [innerHTML]="match.value | markAnyAndNoneOptions | highlightMatch: query"></span>
      </button>
      <div *ngIf="match.item.key === 'false' && matches.length > 1" role="separator" class="dropdown-divider"></div>
    </ng-container>
  </div>
</ng-template>

<div class="form-group" *ngIf="!hide">
  <label class="form-control-label" [for]="'filter_multiselect_typeahead_' + config.field">
    {{ config.label }}
  </label>
  <input
    class="form-control from-control-sm"
    type="text"
    [id]="'filter_multiselect_typeahead_' + config.field"
    [formControl]="formField"
    [typeahead]="dataSource"
    [typeaheadAsync]="true"
    [typeaheadOptionsLimit]="20"
    [typeaheadMinLength]="0"
    [typeaheadLatinize]="true"
    [typeaheadOptionField]="'value'"
    [filterAutofocus]="config.autofocus"
    [optionsListTemplate]="customListTemplate"
    [dropup]="true"
    (typeaheadOnSelect)="onSelect($event)"
    (focus)="onFocus()"
    container="body">
    <small class="form-text text-muted ng-star-inserted">
      <a (click)="reloadList()" class="pointer plain me-1" i18n="@@shared.filter_column.reload_list">Reload list</a>
      <fa-icon [icon]="['fas', 'sync-alt']"
               [spin]="isListReloading">
      </fa-icon>
    </small>
</div>

<div *ngIf="items && items.length > 0" class="mt-2">
  <ng-template ngFor let-item [ngForOf]="items" let-i="index">
    <span *ngIf="item.key !== 'false'" class="badge-item me-2 mb-2">
      <fa-icon
        [icon]="['fas', config.icon || 'circle']"
        [fixedWidth]="true"
        class="me-1">
      </fa-icon>{{ item.value | slice:0:(config.sliceValue ? config.sliceValue : 200) }}<span *ngIf="config.sliceValue && item.value.length > (config.sliceValue || 200)">...</span><span (click)="unlinkItem(i)" class="times pointer ms-1">&times;</span>
    </span>
  </ng-template>
</div>
