import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { CurrentTenantRegister, Filter, FilterService, Logger, SessionService } from '@parashift/shared/services';
import { FilterComponent } from './../filter.component';
import { FilterTabsService } from 'shared/components/filter-tabs/filter-tabs.service';

/**
 * Tenant Users Filter Component
 *
 * Provides a selectbox with the users of the current tenant
 * in order to filter for.
 *
 * It will be configured by JSON object
 * @example
 *  {
 *                 type: 'tenant-users',
 *                 config?: {
 *                   label?: string | 'User',
 *                   defaultValues?: {
 *                     option?: string<user_id>
 *                   }
 *                 }
 *  }
 */
@Component({
  selector: 'filter-tenant-users[filter=tenant-users]',
  templateUrl: './tenant-users.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor]
})
export class TenantUsersComponent extends FilterComponent {
  formField: UntypedFormControl;
  options: { label: string; value: string; }[];
  allowMultipleInstances = false;

  constructor(
    protected route: ActivatedRoute,
    protected filterService: FilterService,
    protected filterTabsService: FilterTabsService,
    protected sessionService: SessionService,
    protected logger: Logger,
    protected currentTenantRegister: CurrentTenantRegister,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
    ) {
    super(route, filterService, filterTabsService, sessionService, logger, destroyRef);
  }

  /**
   * Initializes the user filter.
   */
  initFilter() {
    const initialFilter = this.getInitalValue();
    this.formField = new UntypedFormControl(initialFilter.field);
    this.options = this.getUserOptions();

    this.formField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).forEach((option: any) => {
      this.setListFilter(option);
      this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: option });
      option === '' && this.filterTabsService.removeUserFiltersFilter({ type: this.type, field: this.config.field, value: option });

    });
  }

  /**
   * Sets the filter with a given value. Gets triggered from outside event
   */
  setFilter(filter: Filter) {
    if (filter.type === this.type && filter.field === this.config.field) {
      this.formField.setValue(filter.value, { emitEvent: false, emitModelToViewChange: true });
      this.setListFilter(filter.value);
    }
  }

  /**
   * Sets the user defined filters without triggering new list filter
   */
   setUserFilterFromTab(filter: Filter) {
    if (filter?.type === this.type && filter?.field === this.config.field && filter?.value[0]) {
      const value = filter.value[0] || '';
      this.formField.setValue(value, { emitEvent: false, emitModelToViewChange: true });
      const filters = this.listFilter.filter || {};
      filters[this.config.field] = [value];
      this.listFilter.filter = filters;
    }
  }

  /**
   * Triggeres the event chain for changed filter for other components observing it
   */
  setListFilter(option) {
    const filter = this.listFilter.filter || {};
    filter[this.config.field] = [option];
    this.listFilter.filter = filter;
    this.storeFilter(this.config.field, [option]);

    this.changeFilter(this.listFilter);
  }

  /**
   * Resets current filter to empty value
   */
  resetFilter(forceResetAfterHiding?: boolean) {
    if (!this.formField || (this.hide && !forceResetAfterHiding)) {
      return;
    }

    this.formField.setValue('', { emitEvent: false, emitModelToViewChange: true });
    this.setListFilter('');
    this.filterService.emitFilterChange({ type: this.type, field: this.config.field, value: '' });
  }

  /**
   * Creates the selectbox options
   * returns [ { value: 'option_key: string', label: 'option_label: string' } ]
   */
  getUserOptions() {
    return this.currentTenantRegister.getSortedUserOptions($localize `:@@common.all_users:All users`);
  }

  /**
   * Sorts out inital filter value depending on session stored value or default config value
   */
  getInitalValue() {
    const restoredValue = this.restoredFilter.value;
    let field = '';

    if (restoredValue[0]) {
      field = restoredValue[0];
    } else {
      // field = this.config.defaultValues.field;
      field = '';
    }

    return { field };
  }
}
